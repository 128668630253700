var swiper_banners = new Swiper(".slide_banners", {
  loop: true,
  autoplay: {
      delay: 4500,
      disableOnInteraction: false,
    },
  navigation: {
      nextEl: ".swiper_next-banners",
      prevEl: ".swiper_prev-banners",
  },
});


var swiper_servicos = new Swiper(".slide_servicos", {
    spaceBetween: 30,
    loop: true,
    autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      },
    navigation: {
        nextEl: ".swiper_next",
        prevEl: ".swiper_prev",
    },
});

var slide_mobile_servicos = new Swiper('.slide_servicos-mobile', {
    spaceBetween: 30,
    loop: true,
    autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      },
    navigation: {
        nextEl: ".swiper_next",
        prevEl: ".swiper_prev",
    },
});

var slide_mobile_single = new Swiper('.slide_servicos-single', {
    spaceBetween: 30,
    slidesPerView: 1,
    loop: true,
    autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      },
    navigation: {
        nextEl: ".swiper_next",
        prevEl: ".swiper_prev",
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      789: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1200:{
          slidesPerView: 4,
          spaceBetween: 30,
      }
    },
});

var slide_equipe = new Swiper(".slide_equipe", {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      },
    navigation: {
        nextEl: ".swiper-equipe_next",
        prevEl: ".swiper-equipe_prev",
    },
    breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        789: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1200:{
            slidesPerView: 4,
            spaceBetween: 30,
        }
      },
});

var slide_depoimentos = new Swiper(".slide_depoimentos", {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      },
    navigation: {
        nextEl: ".swiper-depoimentos_next",
        prevEl: ".swiper-depoimentos_prev",
    },
});

var slide_grid = new Swiper(".slide_grid", {
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  autoplay: {
      delay: 4500,
      disableOnInteraction: false,
    },
});
